import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/login/ForgotPassword";
import Login from "../pages/login/Login";

import Header from "./Header";
import Sidebar from "./Sidebar";

import Profile from "../pages/profile/Profile";
import ForgotConfirmation from "../pages/login/ForgotConfirmation";
import ResetPassword from "../pages/login/ResetPassword";
import ErrorBoundary from "../utils/ErrorBoundary";
import { useSelector } from "react-redux";
import NotFoundPage from "../pages/NotFoundPage";
import LoadingDiv from "../components/LoadingDiv";
import MyCameras from "../pages/cameras/MyCameras";
import MyHome from "../pages/myhome";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CameraDetails from "../pages/cameras/CameraDetails";
import WRedirect from "../pages/auths/Auths";
import { Config } from "../Config";
const Body = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { loading } = useSelector((state) => ({
    loading: state?.profile?.loaders?.profile,
  }));

  const location = useLocation();

  const RouteList = [
    // { module: "auths", path: "auths", element: <Auths /> },
    { path: "/", element: <Dashboard /> },
    { module: "login", path: "login", element: <Login /> },
    { module: "forgot", path: "forgot-password", element: <ForgotPassword /> },
    {
      module: "forgot",
      path: "forgot-success",
      element: <ForgotConfirmation />,
    },
    {
      module: "forgot",
      path: "forgot-password/:id",
      element: <ResetPassword />,
    },
    // { module: "reset", path: "registration/:id", element: <ResetPassword /> },
    { module: "reset", path: "registration", element: <ResetPassword /> },
    { module: "cameras", path: "cameras", element: <MyCameras /> },
    { module: "cameras", path: "cameras/subscription/:id", element: <CameraDetails /> },

    { module: "profile", path: "profile", element: <Profile /> },
    { module: "profile", path: `profile/${Config.NodeEnv === "dev" && ":kyc"}`, element: <Profile /> },
    { module: "myhome", path: "my-home", element: <MyHome /> },
    { module: "policy", path: "privacy-policy", element: <PrivacyPolicy /> },
  ];

  const finalRoutes = !loading && RouteList;

  return (<>
    <div
      className="flex flex-col h-screen overflow-hidden "
      data-testid="main-body"
    >



      {
      
location?.pathname?.includes("worldlink_oath") ?
<ErrorBoundary>
              <Routes>
                  <Route path="/worldlink_oath" element={<WRedirect />} /> 
              </Routes>
</ErrorBoundary>
 :
      location?.pathname?.includes("login") ||
      location?.pathname?.includes("forgot") ||
      location?.pathname?.includes("registration") ? (
        <div className=" relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <ErrorBoundary>
            {loading ? (
              <LoadingDiv />
            ) : (
              <Routes>
                {finalRoutes?.map((each) => (
                  <Route
                    path={each.path}
                    element={each.element}
                    key={each.path}
                  />
                ))}
              </Routes>
            )}
          </ErrorBoundary>
        </div>
      ) : (
        <>
          {/* Sidebar */}
          {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

          {/* Content area */}

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className=" flex-1 overflow-y-auto overflow-x-hidden lg:px-28 xs:px-1">
            <ErrorBoundary>
              {loading ? (
                <LoadingDiv />
              ) : (
                <Routes>
                  {finalRoutes?.map((each) => (
                    <Route
                      path={each.path}
                      element={each.element}
                      key={each.path}
                    />
                  ))}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              )}
            </ErrorBoundary>
          </div>
        </>
      )}
    </div>
  </>);
};

export default Body;
