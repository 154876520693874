import React from 'react';

const SelectInput = ({
  label,
  name,
  onChange,
  error,
  value,
  options,
  disabled,
  hideDefault,
  alignLeft,
  keyval,
  className,
  customLabel,
}) => {

  const displayError = (error) => {
    return error ? (
      <div className="invalid-feedback position-absolute text-sm text-red-500">{error[0]}</div>
    ) : (
      <></>
    );
  };

  return (
    <div className={` col-12  `} >
      {label ? (
        <label
          className="flex text-sm text-gray-500"
        >
          {label}
        </label>
      ) : (
        ""
      )}
      <select
        disabled={disabled}
        className={ `${error ? 'form-select is-invalid' : 'text-sm  rounded-md'} w-full`}
        value={value || value === 0 ? value : ''}
        name={name}
        onChange={(e) => onChange(e, name)}
      >
        {!hideDefault || value.length < 0 && (
          <option value="" className="text-gray-500 ">
            {customLabel ? customLabel : `OPEN TO SELECT ${label?.toUpperCase()}`}
          </option>
        )}
        {options &&
          options.map((o, i) => (
            <option className='' key={i} value={keyval ? o[keyval[1]] : o["value"]}>
              {keyval ? o[keyval[0]] : o["label"]}
            </option>
          ))}
      </select>
      {displayError(error)}
    </div>
  );
};

export default SelectInput;
